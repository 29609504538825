import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { SET_CURRENT_MATERIAL_DATA } from '../../redux/StoreConstants'
import { Box, Divider, Paper, Switch, Typography } from '@material-ui/core'
import * as THREE from "three";

let animationFrameReq

const MaterialSelector = () => {
  const { currentMaterialData } = useSelector(state => state['materialStore'])
  const { currentNodeData } = useSelector(state => state['nodeStore'])
  const dispatch = useDispatch()

  const [scale, setScale] = useState(1)
  const [normalScale, setNormalScale] = useState(1)

  const [values, setValues] = useState({})

  let [diamondColor, setDiamondColor ] = useState(['#ff0000','#ff0000','#ff0000','#ff0000','#ff0000','#ff0000']);


  useEffect(() => {
    if (currentMaterialData) {
      if (currentMaterialData?.uvScale?.u !== scale) {
        setScale(currentMaterialData?.uvScale?.u)
      }
      if (currentMaterialData.normalScale.x !== normalScale) {
        setNormalScale(currentMaterialData.normalScale.x)
      }
      setValues({
        ...currentMaterialData
      })
    }
  }, [currentMaterialData])


  const handleScaleChange = (e) => {
    setScale(e.target.value)
    doUpdate({
      ...currentMaterialData,
      uvScale: {
        u: e.target.value,
        v: e.target.value
      }
    })
  }

  const handleNormalScaleChange = (e) => {
    setNormalScale(e.target.value)
    doUpdate({
      ...currentMaterialData,
      normalScale: {
        x: e.target.value,
        y: e.target.value
      }
    })
  }

  const handleControlChange = (e, newValue) => {
    doUpdate({
      ...currentMaterialData,
      [e.target.dataset.key]: newValue
    })
  }

  const handleInputChange = (e) => {
    handleControlChange(e, e.target.value)
  }

  const handleDiamondColorChange = (e) => {
    // get the current elements value:

    let newDiamondColor = diamondColor;
    newDiamondColor[e.target.getAttribute('data-key').split('_')[1]] = e.target.value;

    diamondColor[e.target.getAttribute('data-key').split('_')[1]] = e.target.value;

    setDiamondColor( diamondColor);
    console.log("diamondColor: ", diamondColor);

  }

  const doUpdate = (newData) => {
    /*dispatch({
      type: SET_CURRENT_MATERIAL_DATA,
      payload: newData
    })*/
    cancelAnimationFrame(animationFrameReq)
    animationFrameReq = requestAnimationFrame(() => {
      dispatch({
        type: SET_CURRENT_MATERIAL_DATA,
        payload: newData
      })
    })
  }

  const doGridUpdate = (newData) => {
    /*dispatch({
      type: SET_CURRENT_MATERIAL_DATA,
      payload: newData
    })*/
    cancelAnimationFrame(animationFrameReq)
    animationFrameReq = requestAnimationFrame(() => {
      dispatch({
        type: SET_CURRENT_GRID_DATA,
        payload: newData
      })
    })
  }

  const changeColor = (e) => {




    // get the current clicked Element
    const clickedElement = e.target;

    // get the data-key attribute
    const key = clickedElement.getAttribute('data-key').split('_');

    let i =  key.shift();

    // generate a random color
    let randomColor = Math.floor(Math.random()*16777215).toString(16);

    // pad the color with zeros
    while(randomColor.length < 6) {
        randomColor = '0' + randomColor;
    }

    randomColor = '#' + randomColor;


    if (diamondColor[i]) {
      randomColor = diamondColor[i];
    }

    // change the color of the clicked cell
    clickedElement.style.backgroundColor = randomColor;



    var mtrl = currentNodeData.node.material.clone();
    currentNodeData.grid[i][key[0]][key[1]].material = mtrl;
    currentNodeData.grid[i][key[0]][key[1]].material.color.set(randomColor.toString());
    // g_grids[side][i][j].material.color.set(g_grids[side][i][j].color);
    currentNodeData.grid[i][key[0]][key[1]].material.needsUpdate = true;


    currentNodeData.render();

    //
    // // set the color in the state
    // doUpdate({
    //     ...currentMaterialData
    // });



  }

  if (!currentMaterialData) {
    return null
  }

  let stepSize = 0.001
  if (values?.bumpScale > 0.01) {
    stepSize = 0.01
  }
  if (values?.bumpScale > 0.5) {
    stepSize = 0.1
  }

  return (
    <>
      <Paper>

        {Array.from({ length: 6 }).map((_, side) => (

            <Box p={2} key={'box'+side}>


              <table key={side}>
                  <tbody>
                      {Array.from({ length: 11 }).map((_, i) => (
                          <tr key={side+'_'+i}>
                              {Array.from({ length: 11 }).map((_, j) => (
                                  <td key={side+'_'+i+'_'+j} data-key={side+'_'+i+'_'+j} style={{ width: '20px', height: '20px', border: '#aaa 1x solid', background: '#ccc' }} onClick={changeColor}>

                                  </td>
                              ))}
                          </tr>
                      ))}
                  </tbody>
              </table>

            <Box display='flex' mt={1} mb={1} >

              <input
                  value={diamondColor[side] ?? 0}
                  type="color"
                  data-key={'diamondColor_'+side}
                  min={0}
                  max={1}
                  step={0.1}
                  onChange={handleDiamondColorChange} style={{ width: 50 }} />
            </Box>

            <Divider />
          </Box>

        ))}



        <Box p={2}>

          <Box display='flex' mt={1} mb={1} justifyContent='space-between'>
            <Typography>Textures Scale</Typography>
            <input
              value={scale}
              type="number"
              min={0}
              max={20}
              step={0.1}
              onChange={handleScaleChange} style={{ width: 50 }} />
          </Box>

          <Divider />

          <Box display='flex' mt={1} mb={1} justifyContent='space-between'>
            <Typography>Normal Map Scale</Typography>
            <input
              value={normalScale}
              disabled={values?.normalMap === ''}
              type="number"
              data-key='displacementScale'
              min={0}
              max={20}
              step={0.01}
              onChange={handleNormalScaleChange} style={{ width: 50 }} />
          </Box>

          <Divider />

          <Box display='flex' mt={1} mb={1} justifyContent='space-between'>
            <Typography>Bump map Scale</Typography>
            <input
              value={values?.bumpScale ?? 0}
              disabled={currentMaterialData.bumpMap === ''}
              type="number"
              data-key='bumpScale'
              min={0}
              max={2}
              step={stepSize}
              onChange={handleInputChange} style={{ width: 50 }} />
          </Box>

          <Box display='flex' mt={1} mb={1} justifyContent='space-between'>
            <Typography>Metalness</Typography>
            <input
              value={values?.metalness ?? 0}
              type="number"
              data-key='metalness'
              min={0}
              max={1}
              step={0.01}
              onChange={handleInputChange} style={{ width: 50 }} />
          </Box>

          <Box display='flex' mt={1} mb={1} justifyContent='space-between'>
            <Typography>Roughness</Typography>
            <input
              value={values?.roughness ?? 0}
              type="number"
              data-key='roughness'
              min={0}
              max={1}
              step={0.01}
              onChange={handleInputChange} style={{ width: 50 }} />
          </Box>

          <Divider />

          <Box display='flex' mt={1} mb={1} justifyContent='space-between'>
            <Typography>Displacement Scale</Typography>
            <input
              value={values?.displacementScale ?? 0}
              disabled={values?.displacementMap === ''}
              type="number"
              data-key='displacementScale'
              min={0}
              max={1}
              step={0.01}
              onChange={handleInputChange} style={{ width: 50 }} />
          </Box>
          <Box display='flex' mt={1} mb={1} justifyContent='space-between'>
            <Typography>Displacement Bias</Typography>
            <input
              value={values?.displacementBias ?? 0}
              disabled={values?.displacementMap === ''}
              type="number"
              data-key='displacementBias'
              min={0}
              max={1}
              step={0.01}
              onChange={handleInputChange} style={{ width: 50 }} />
          </Box>

          <Divider />

          <Box display='flex' mt={1} mb={1} justifyContent='space-between'>
            <Typography>EnvMap Intensity</Typography>
            <input
              value={values?.envMapIntensity ?? 0}
              type="number"
              data-key='envMapIntensity'
              min={0}
              max={1}
              step={0.1}
              onChange={handleInputChange} style={{ width: 50 }} />
          </Box>

          <Divider />

          <Box display='flex' mt={1} mb={1} justifyContent='space-between'>
            <Typography>Ambient occlusion intensity &nbsp;</Typography>
            <input
              value={values?.aoMapIntensity ?? 0}
              type="number"
              data-key='aoMapIntensity'
              min={0}
              max={1}
              step={0.1}
              onChange={handleInputChange} style={{ width: 50 }} />
          </Box>

          <Divider />

          <Box display='flex' mt={1} mb={1} justifyContent='space-between'>
            <Typography>Color</Typography>
            <input
              value={values?.color ?? 0}
              type="color"
              data-key='color'
              min={0}
              max={1}
              step={0.1}
              onChange={handleInputChange} style={{ width: 50 }} />
          </Box>

          <Divider />

          <Box display='flex' mt={1} mb={1} justifyContent='space-between'>
            <Typography>Emissive Intensity</Typography>
            <input
              value={values?.emissiveIntensity ?? 0}
              type="number"
              data-key='emissiveIntensity'
              min={0}
              max={1}
              step={0.1}
              onChange={handleInputChange} style={{ width: 50 }} />
          </Box>
          <Box display='flex' mt={1} mb={1} justifyContent='space-between'>
            <Typography>Emissive color</Typography>
            <input
              value={values?.emissive ?? 0}
              type="color"
              data-key='emissive'
              min={0}
              max={1}
              step={0.1}
              onChange={handleInputChange} style={{ width: 50 }} />
          </Box>

          <Divider />

          <Box display='flex' mt={1} mb={1} alignItems='center'>
            <Typography>Wireframe</Typography>
            <Switch
              inputProps={{
                'data-key': 'wireframe'
              }}
              checked={!!values?.wireframe}
              onChange={handleControlChange}
              color='primary'
            />
          </Box>

          <Divider />

          <Box display='flex' mt={1} mb={1} alignItems='center'>
            <Typography>Transparent</Typography>
            <Switch
              inputProps={{
                'data-key': 'transparent'
              }}
              checked={!!values?.transparent}
              onChange={handleControlChange}
              color='primary'
            />
          </Box>
          <Box display='flex' mt={1} mb={1} justifyContent='space-between'>
            <Typography>Opacity</Typography>
            <input
              value={values?.opacity ?? 0}
              disabled={!values?.transparent}
              type="number"
              data-key='opacity'
              min={0}
              max={1}
              step={0.05}
              onChange={handleInputChange} style={{ width: 50 }} />
          </Box>


        </Box>
      </Paper>
    </>
  )
}

export default MaterialSelector
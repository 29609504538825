import {Paper} from "@material-ui/core"
import React from "react"
import styled from 'styled-components';
import DracoSelector from "../components/DracoSelector"
import MaterialSelector from "../components/MaterialSelector"
import MeshSelector from "../components/MeshSelector"
import NodeSelector from "../components/NodeSelector"
import ProductSelector from "../components/ProductSelector"
import Viewer3D from "../components/Viewer3D"
import MaterialSettings from "../components/MaterialSettings/MaterialSettings"

export default function Home() {
  return (
    <>
      <StyledPaper>
        <DracoSelector />

      </StyledPaper>
      <ConfiguratorSideBar>
          <ProductSelector />
          <NodeSelector />
          <MeshSelector />
          <MaterialSelectHolder>
              <MaterialSelector />
          </MaterialSelectHolder>
          <MaterialSettingHolder>
              <MaterialSettings />
          </MaterialSettingHolder>
      </ConfiguratorSideBar>

      <Viewer3D />
    </>
  )
}

const ConfiguratorSideBar = styled.div`
    background: #fafafa;
    padding: 0;
    position: absolute;
    top: 80px;
    left: 70%;
    bottom: 0;
    right: 0;
    padding: 25px 20px;
    border-radius: 0;
    overflow-y: scroll;
    border-left: #ccc 1px solid;
    box-shadow: 0 0 30px inset rgba(0, 0, 0, 0.2);
    z-index: 3;
`

const StyledPaper = styled.div`
    background: #fff;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    width: 100%;
    border-radius: 0;
    display: flex;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    z-index: 5;
    
`

const MaterialSettingHolder = styled.div`
  padding: 1em;
    //position: absolute;
    //top: 1em;
    //right: 2em;
    z-index: 1;
`

const MaterialSelectHolder = styled.div`
    padding: 0.5em;
    //position: absolute;
    //bottom: 1em;
    //left: 1em;
    z-index: 1;
`